<template>
  <Toast />

  <div class="grid">
    <div
      class="col-12"
      style="padding-left: 0; padding-right: 0; padding-bottom: 0"
    >
      <div style="padding: 20px">
        <div class="flex justify-content-center flex-wrap card-container">
          <div class="flex-column">
            <div class="flex justify-content-center">
              <img alt="Logo" :src="topbarImage()" style="width: 180px" />
            </div>
            <h6>System for Troubleshooting and Operational Risk Management</h6>
          </div>
        </div>
      </div>
    </div>
    <!-- 
    <div v-if="userHasRoRole()" class="flex col-12 lg:col-6 xl:col-3" style="padding: 0 !important; gap: 24px">
      <div class="card">
        <div class="flex justify-content-between" style="min-width: 250px;">
          <div>
            <span class="block text-500 font-medium mb-3">Novos Incidentes (STORM)</span>
            <div class="text-900 font-medium text-xl align-items-center flex">
              {{ block1.total }}
              <Badge class="ml-3 mr-1" :value="'low' in this.block1 ? String(this.block1.low) : '0'"
                style="background-color: #307ae0" v-tooltip.top="'Baixo'"></Badge>
              <Badge class="mr-1" :value="'medium' in this.block1 ? String(this.block1.medium) : '0'
                " style="background-color: #ffca43" v-tooltip.top="'Médio'"></Badge>
              <Badge class="mr-1" :value="'high' in this.block1 ? String(this.block1.high) : '0'"
                style="background-color: #eb3e48" v-tooltip.top="'Alto'"></Badge>
              <Badge :value="'critical' in this.block1 ? String(this.block1.critical) : '0'
                " style="background-color: #754cc5" v-tooltip.top="'Crítico'"></Badge>
            </div>
          </div>

          <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
            style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-percentage text-blue-500 text-xl"></i>
          </div>
        </div>
        <div class="flex mt-4" style="white-space: nowrap">
          <span :style="{ color: block1.color }" class="font-medium">{{
            block1.metric }} </span>
          <span class="text-500 ml-1">
            que o mês passado ({{ block1.lastMonthTotal }})</span>
        </div>
      </div>

      <div class="flex flex-column" style="min-width: 200px">
        <a class="flex card align-items-center"
          href="https://app.powerbi.com/groups/me/reports/826b65ea-cffd-47c6-b239-ba334286d431/ReportSection849d099e15d3bb518875?ctid=16e7cf3f-6af4-4e76-941e-aecafb9704e9&experience=power-bi&clientSideAuth=0"
          target="_blank" style="height: 50%;">
          <div class="flex justify-content-between align-items-center" style="width: 100%">
            <div class="flex align-items-center" style="gap: 8px">
              <img src="/images/Power_BI_Logo_original.svg" style="width: 24px; height: 24px" />
              <div style="color: #05132A; font-weight: 500;">
                Incidentes
              </div>
            </div>
            <i class="pi pi-external-link" style="font-size: 1.2rem; padding: 0px 2px; color: gray"></i>
          </div>
        </a>
        <a class="flex card align-items-center"
          href="https://app.powerbi.com/groups/me/reports/c8d03f09-3423-4d6f-a64e-1aed9ed6b74a/ReportSection?ctid=16e7cf3f-6af4-4e76-941e-aecafb9704e9&experience=power-bi"
          target="_blank" style="height: 50%;">
          <div class="flex justify-content-between align-items-center" style="width: 100%">
            <div class="flex align-items-center" style="gap: 8px">
              <img src="/images/Power_BI_Logo_original.svg" style="width: 24px; height: 24px" />
              <div style="color: #05132A; font-weight: 500;">
                DRO
              </div>
            </div>
            <i class="pi pi-external-link" style="font-size: 1.2rem; padding: 0px 2px; color: gray"></i>
          </div>
        </a>
      </div>
    </div>

    
    <div v-show="userHasRoRole() == false" class="col-12">
      <div class="flex">
        <div class="flex" style="width: 25%;">
          <Button class="buttonTab flex justify-content-center"
            style="width: 100%; background-color: white; padding: 0xp ; border-width: 0px 0px 2px 0px; border-radius: 0px">
            <div class="" style="color: #05132A; font-size: 16px; font-weight: normal; width: fit-content;">
              {{ this.$langGetValue("myIncidents") }}
            </div>
            <i v-badge.info="myIncidentsSize" class="pi pi-inbox ml-2" style="color: black"></i>
          </Button>
        </div>
        <div class="flex" style="width: 75%; justify-content: end; border-bottom: 2px solid #cacaca; padding: 12px 0px">
          <button :disabled="loadingIconLocal" @click="refresh" :title="this.$langGetValue('buttonReload')"
            severity="secondary"
            class="p-button p-component p-button-icon-only p-button-secondary p-button-rounded p-button-outlined p-button-sm">
            <i :class="{
              'pi pi-refresh': loadingIconLocal == false,
              'pi pi-spin pi-spinner': loadingIconLocal == true,
            }" style="font-weight: 900"></i>
          </button>
        </div>
      </div>

      <div class="mt-2" v-if="myIncidents != null">
        <IncidentResult :analyticsOnSearch="false" :query="myIncidents" @update-length="updateLengthMyIncidents"
          buttonMode="visualizar">
        </IncidentResult>
      </div>

      <div
          v-if="this.myIncidentsSize == 0"
          style="margin: 64px 0px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <img src="images/not_found.svg" style="width: 100px; padding-bottom: 16px;" />
          <div class="body2" style="width: 30%; display: flex; justify-content: center; text-align: center">
            {{ this.$langGetValue('myIncidentsEmpty') }}
          </div>
        </div>
    </div>

    
    <div class="col-12" v-if="userHasRoRole()">
      <div class="tabsListHome flex justify-content-between" style="padding-top: 16px; padding-bottom: 16px;">
        <SelectButton class="customSelectButton" v-model="incidentMenuSelected" :options="incidentMenu" dataKey="id"
          aria-labelledby="custom" style="width: 95%; display: inline-flex;">
          <template #option="slotProps">
            <div class="mr-2 font-medium">
              {{ slotProps.option.name }}
            </div>
            <i v-badge.info="slotProps.option.size" class="pi pi-inbox"></i>
          </template>
        </SelectButton>
        <div class='flex align-items-center justify-content-end' style="width: 5%; border-bottom: 2px solid #cacaca;">
          <button :disabled="loadingIconLocal" @click="refresh" :title="this.$langGetValue('buttonReload')"
            severity="secondary"
            class="p-button p-component p-button-icon-only p-button-secondary p-button-rounded p-button-outlined p-button-sm">
            <i :class="{
              'pi pi-refresh': loadingIconLocal == false,
              'pi pi-spin pi-spinner': loadingIconLocal == true,
            }" style="font-weight: 900"></i>
          </button>
        </div>
      </div>

      <div v-show="incidentMenuSelected != null &&
        incidentMenuSelected.name == this.$langGetValue('myIncidents')
        ">
        <div v-if="myIncidents != null">
          <IncidentResult :analyticsOnSearch="false" :query="myIncidents" @update-length="updateLengthMyIncidents"
            buttonMode="visualizar">
          </IncidentResult>
        </div>
        <div
          v-if="this.incidentMenu[0].size == 0 && this.incidentControl"
          style="margin: 64px 0px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <img src="images/not_found.svg" style="width: 100px; padding-bottom: 16px;" />
          <div class="body2" style="width: 30%; display: flex; justify-content: center; text-align: center">
            {{ this.$langGetValue('myIncidentsEmpty') }}
          </div>
        </div>
      </div>

      <div v-show="incidentMenuSelected != null &&
        incidentMenuSelected.name == this.$langGetValue('assignIncidents')
        " v-if="incidentsToAssign != null">
        <IncidentResult :analyticsOnSearch="false" :query="incidentsToAssign"
          @update-length="updateLenghtIncidentsToAssign" buttonMode="atribuir">
        </IncidentResult>
      </div>

      <div v-show="incidentMenuSelected != null &&
        incidentMenuSelected.name == this.$langGetValue('assignedIncidents')
        " v-if="incidentsAssigned != null">
        <Accordion class="customAccordionTab1">
          <AccordionTab v-for="value in incidentsAssignedArray" :key="value" style="background-color: #f9f9f9">
            <template #header>
              <div class="flex align-items-center justify-content-start ml-4">
                <div class="h4" style="width: 200px;">
                  {{ emailToName(value.user) }}
                </div>
              </div>
              <div class="flex justify-content-evenly align-items-center" style="width:fit-content; margin-left: 16px">
                <Badge :value="roUsersRiskCount != null
                  ? String(roUsersRiskCount[value.user].medium)
                  : '0'
                  " style="background-color: var(--color_incident_medium); margin-right: 2px"
                  v-tooltip.top="this.$langGetValue('mediumMario')"></Badge>
                <Badge :value="roUsersRiskCount != null
                  ? String(roUsersRiskCount[value.user].high)
                  : '0'
                  " style="background-color: var(--color_incident_high); margin-right: 2px"
                  v-tooltip.top="this.$langGetValue('highMario')"></Badge>
                <Badge :value="roUsersRiskCount != null
                  ? String(roUsersRiskCount[value.user].critical)
                  : '0'
                  " style="background-color: var(--color_incident_critical)"
                  v-tooltip.top="this.$langGetValue('criticalMario')"></Badge>
                <div class="body3"
                  style="font-weight: 700; color: var(--color_gray_dark); margin-left: 20px; margin-right: 4px">
                  Total
                </div>
                <Badge :value="roUsersRiskCount != null
                  ? String(value.total)
                  : '0'
                  " style="background-color: var(--color_gray_dark);" v-tooltip.top="'Total'"></Badge>
              </div>

            </template>
            <IncidentResult :analyticsOnSearch="false" :query="value.payload" buttonMode="revisar">
            </IncidentResult>
          </AccordionTab>
        </Accordion>
      </div>

      <div v-show="incidentMenuSelected != null &&
        incidentMenuSelected.name == this.$langGetValue('reviewedByMe')">
        <div v-if="incidentsReviewedByMe != null">
          <IncidentResult :analyticsOnSearch="false" :query="incidentsReviewedByMe"
            @update-length="updateLenghtIncidentsReviewedByMe" buttonMode="visualizar">
          </IncidentResult>
        </div>
        <div v-if="this.incidentMenu[3].size == 0"
          style="margin: 64px 0px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <img src="images/not_found.svg" style="width: 100px; padding-bottom: 16px;" />
          <div class="body2" style="width: 30%; display: flex; justify-content: center; text-align: center">
            {{ this.$langGetValue('reviewedByMeEmpty') }}
          </div>
        </div>
      </div>

      <div v-if="userHasBDPORole() && incidentMenu.length == 5" v-show="incidentMenuSelected != null &&
        incidentMenuSelected.name === 'BDPO'">
        <div class="h3" style="padding: 16px">
          <div v-if="this.incidentMenu[4].size == 0">
            <Accordion class="customAccordionTab1">
              <AccordionTab v-for="state in bdpoIncidentStates" :key="state">
                <template #header>
                  <div class="flex align-items-center justify-content-start ml-4">
                    <div class="h4" style="width: 200px;">
                      {{ state.state }}
                    </div>
                  </div>
                </template>
                <IncidentResult :analyticsOnSearch="false" :query="incidentsToAssign"
                buttonMode="bdpo">
                </IncidentResult>
              </AccordionTab>
            </Accordion>
          </div>
          <div v-if="this.incidentMenu[4].size != 0"
            style="margin: 64px 0px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <img src="images/not_found.svg" style="width: 100px; padding-bottom: 16px;" />
            <div class="body2" style="width: 30%; display: flex; justify-content: center; text-align: center">
              {{ this.$langGetValue('bdpoIncidentsEmpty') }}
            </div>
          </div>
        </div>
      </div> 
    </div>-->

    <dashboard :id="'dashExample'">
      <dash-layout
        v-for="layout in dlayouts"
        v-bind="layout"
        :debug="true"
        :key="layout.breakpoint"
      >
        <dash-item
          v-for="item in layout.items"
          v-bind:sync="item"
          :key="item.id"
        >
          <div class="content"></div>
        </dash-item>
      </dash-layout>
    </dashboard>
  </div>

  <Dialog
    class="emailDialog"
    v-model:visible="sendIncidentModal"
    style="width: 30vw"
    :modal="true"
    :showHeader="false"
    :dismissable-mask="false"
  >
    <Card v-if="notShowAnimation == false">
      <template #content>
        <Vue3Lottie
          ref="lottieContainer"
          :animationData="AnimationUser"
          :loop="1"
          :height="200"
          :width="200"
        />
      </template>
    </Card>
  </Dialog>
</template>
<script>
// import IncidentResult from "../components/IncidentResult.vue";
// import UserService from "../service/UserService";
// import Util from "../service/Util";
// import IncidentService from "../service/IncidentService";
// import EventBus from "../AppEventBus";
// import AnimationUser from "../assets/animations/AnimationUser.json";
import { Vue3Lottie } from "vue3-lottie";
// import DragDialog from "../components/DragDialog.vue";
// import ExpandMenu from "../components/ExpandMenu.vue";
// import Graph from "./teste.vue";
import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash";

export default {
  components: {
    Dashboard,
    DashLayout,
    DashItem,
    // IncidentResult,
    Vue3Lottie,
    // DragDialog,
    // ExpandMenu,
    // Graph
  },
  data() {
    return {
      dlayouts: [
        {
          breakpoint: "xl",
          numberOfCols: 12,
          items: [
            { id: "1", x: 0, y: 0, width: 1, height: 1 },
            { id: "2", x: 1, y: 0, width: 2, height: 1 },
          ],
        },
        {
          breakpoint: "lg",
          breakpointWidth: 1200,
          numberOfCols: 10,
          items: [
            { id: "1", x: 0, y: 0, width: 1, height: 1 },
            { id: "2", x: 1, y: 0, width: 2, height: 1 },
          ],
        },
        {
          breakpoint: "md",
          breakpointWidth: 996,
          numberOfCols: 8,
          items: [
            { id: "1", x: 0, y: 0, width: 1, height: 1 },
            { id: "2", x: 1, y: 0, width: 2, height: 1 },
          ],
        },
        {
          breakpoint: "sm",
          breakpointWidth: 768,
          numberOfCols: 4,
          items: [
            { id: "1", x: 0, y: 0, width: 1, height: 1 },
            { id: "2", x: 1, y: 0, width: 2, height: 1 },
          ],
        },
        {
          breakpoint: "xs",
          breakpointWidth: 480,
          numberOfCols: 2,
          items: [
            { id: "1", x: 0, y: 0, width: 1, height: 1 },
            { id: "2", x: 1, y: 0, width: 1, height: 1 },
          ],
        },
        {
          breakpoint: "xxs",
          breakpointWidth: 0,
          numberOfCols: 1,
          items: [
            {
              id: "1",
              x: 0,
              y: 0,
              width: 1,
              height: 1,
            },
            { id: "2", x: 0, y: 1, width: 1, height: 1 },
          ],
        },
      ],
    };
  },
  mounted() {},
  unmounted() {},
  created() {},
  watch: {},
  methods: {
    topbarImage() {
      return this.$appState.darkTheme
        ? "images/logo_storm_white_font.png"
        : "images/logo_storm_black_font.png";
    },},
};
</script>

<style>
.p-accordion .p-accordion-header .p-accordion-header-link {
  background-color: transparent;
  border-width: 0px 0px 1px 0px;
  border-radius: 0px;
  padding: 16px;
}

.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background-color: var(--color_brand_xlight);
}

.p-accordion .p-accordion-tab .p-toggleable-content .p-accordion-content {
  border-radius: 0px;
  border-width: 0px 0px 1px 0px;
  padding: 0px 0px 24px 0px;
}

.emailDialog .p-dialog-content {
  background-color: rgba(0, 0, 0, 0);
}

.emailDialog {
  box-shadow: none;
}

.customAutoCompleteEmail .p-autocomplete,
.customAutoCompleteEmail .p-inputtext {
  width: 100%;
}

.customAutoCompleteEmail .p-inputtext .p-autocomplete-token {
  margin-top: 3px;
  margin-left: 3px;
}

.tabBDPO .customSelectButton .p-button {
  width: 20%;
  border-radius: 0px;
  font-size: 16px;
  font-weight: regular;
  justify-content: center;
  border-width: 0px 0px 2px 0px;
  border-color: var(--color_gray_light);
  border-style: solid;
  padding: 24px 0px 24px 0px;
}

.customSelectButton .p-button {
  width: 25%;
  border-radius: 0px;
  font-size: 16px;
  font-weight: regular;
  justify-content: center;
  border-width: 0px 0px 2px 0px;
  border-color: var(--color_gray_light);
  border-style: solid;
  padding: 24px 0px 24px 0px;
}

.customSelectButton .p-button .p-button-label {
  color: #05132a;
}

.p-selectbutton .p-button.p-highlight {
  border-color: var(--color_brand_medium);
}

.customSelectButton {
  border-radius: 0px;
}

.customSelectButton .p-button:focus,
.buttonTab:focus {
  outline: none;
  box-shadow: none;
}

.p-overlay-badge .p-badge {
  background-color: var(--color_brand_medium);
}

.p-badge {
  padding: 0px;
}
</style>
